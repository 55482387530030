import React from 'react'

const Loading = ({ category }) => {
  return (
    <div className="bg-blue-500 flex flex-col justify-center opacity-90 items-center absolute top-0 left-0 right-0 bottom-0 text-white text-lg">
      <h2 className="text-5xl">Loading courses, category: {category ? category : 'any'}...</h2>
    </div>
  )
}

export default Loading