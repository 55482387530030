import React from 'react'

const Footer = () => {
  return (
    <footer className="footer footer-center p-0.5 bg-primary text-primary-content fixed bottom-0 left-0 right-0">
      <div className="w-full h-0.5 bg-gray-400"></div>
    </footer>
  )
}

export default Footer
