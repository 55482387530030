import { useForm } from "react-hook-form";
import { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const ResetPassword = () => {
  const [apiError, setApiError] = useState();
  const [apiSuccess, setApiSuccess] = useState();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const newPassword = watch("new_password");

  const onFormSubmit = async (data) => {
    const token = searchParams.get("token");
    const response = await fetch(`${BASE_URL}users/reset-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token, new_password: data.new_password }),
    });

    if (response.ok) {
      setApiError(null);
      setApiSuccess("Password reset successful. You can now log in.");
      setTimeout(() => navigate("/login"), 3000);
    } else {
      let errorResponse = await response.json();
      setApiError(errorResponse["detail"]);
    }
  };

  const onErrors = (errors) => console.error(errors);

  return (
    <div className="mx-auto p-10 rounded-lg shadow-2xl">
      <h2 className="text-xl text-primary text-center font-bold my-2">
        Reset Password
      </h2>

      <form onSubmit={handleSubmit(onFormSubmit, onErrors)}>
        <div className="flex flex-col justify-center items-center">
          <input
            type="password"
            placeholder="New Password"
            className="input input-bordered input-accent w-full max-w-xs m-3"
            name="new_password"
            {...register("new_password", { required: "The new password is required" })}
          />
          {errors?.new_password && errors.new_password.message}

          <input
            type="password"
            placeholder="Confirm New Password"
            className="input input-bordered input-accent w-full max-w-xs m-3"
            name="confirm_new_password"
            {...register("confirm_new_password", {
              required: "Please confirm your new password",
              validate: (value) =>
                value === newPassword || "The passwords do not match",
            })}
          />
          {errors?.confirm_new_password && errors.confirm_new_password.message}

          <button className="btn btn-outline btn-accent m-3 btn-block">
            Reset Password
          </button>
        </div>
      </form>

      {apiError && (
        <div className="alert alert-error shadow-lg">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="stroke-current flex-shrink-0 h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <span>{apiError}</span>
          </div>
        </div>
      )}

      {apiSuccess && (
        <div className="alert alert-success shadow-lg">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="stroke-current flex-shrink-0 h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
            <span>{apiSuccess}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResetPassword;