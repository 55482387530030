
import RestService from './RestService';

class CourseService extends RestService {
  constructor() {
    super(process.env.REACT_APP_BASE_URL, 'courses'); 
  }

  // Override getList to handle pagination
  async getList(queryOptions) {
    const response = await this.client.get('', { params: queryOptions });
    return response.data;
  }
}

export default new CourseService(); 