import axios, { AxiosInstance } from 'axios';

class RestService {
  constructor(baseUrl, baseRoute) {
    this.client = axios.create({
      baseURL: `${baseUrl}${baseRoute}`
    });
  }

  async getList(queryOptions) {
    const response = await this.client.request({
      method: 'GET',
      data: queryOptions
    });

    return response.data;
  }

  async get(id) {
    const response = await this.client.request({
      method: 'GET',
      url: id
    });

    return response.data;
  }

  async save(entity) {
    return entity.id
      ? await this.put(entity)
      : await this.post(entity);
  }

  async delete(id) {
    await this.client.request({
      method: 'DELETE',
      url: id
    });
  }

  async post(entity) {
    const response = await this.client.request({
      method: 'POST',
      data: entity
    });

    return response.data;
  }

  async put(entity) {
    const response = await this.client.request({
      method: 'PUT',
      url: entity.id,
      data: entity
    });

    return response.data;
  }

  async patch(id, entity) {
    const response = await this.client.request({
      method: 'PATCH',
      url: id,
      data: entity
    });

    return response.data;
  }
}

export default RestService;