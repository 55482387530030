import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const VerifyEmail = () => {
  const [searchParams] = useSearchParams();
  const [verificationStatus, setVerificationStatus] = useState(null);

  useEffect(() => {
    const token = searchParams.get("token");
    const verifyEmail = async () => {
      try {
        const response = await fetch(`${BASE_URL}users/verify-email?token=${token}`); 
            if (response.ok) {
          setVerificationStatus("success");
        } else {
          setVerificationStatus("failure");
        }
      } catch (error) {
        setVerificationStatus("failure");
      }
    };

    if (token) {
      verifyEmail();
    } else {
      setVerificationStatus("failure");
    }
  }, [searchParams]);

  return (
    <div className="mx-auto p-10 rounded-lg shadow-2xl">
      {verificationStatus === "success" && (
        <>
          <h2 className="text-xl text-primary text-center font-bold my-2">
            Email verified!
          </h2>
          <p className="text-center">
            Your email has been successfully verified. You can now log in.
          </p>
        </>
      )}
      {verificationStatus === "failure" && (
        <>
          <h2 className="text-xl text-primary text-center font-bold my-2">
            Email verification failed!
          </h2>
          <p className="text-center">
            There was an error verifying your email. Please try again later.
          </p>
        </>
      )}
    </div>
  );
};

export default VerifyEmail;