import { useForm } from "react-hook-form";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Register = () => {
  const [apiError, setApiError] = useState();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const password = watch("password");

  const onFormSubmit = async (data) => {
    console.log(data);
    const response = await fetch(`${BASE_URL}users/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      setApiError(null);
      navigate("/email-verification-sent", { replace: true }); 
        // navigate("/login", { replace: true });
    } else {
      let errorResponse = await response.json();
      setApiError(errorResponse["detail"]);
    }
  };

  const onErrors = (errors) => console.error(errors);

  return (
    <div className="mx-auto p-10 rounded-lg shadow-2xl">
      <h2 className="text-xl text-primary text-center font-bold my-2">
        Register page
      </h2>

      <form onSubmit={handleSubmit(onFormSubmit, onErrors)}>
        <div className="flex flex-col justify-center items-center">
          <input
            type="text"
            placeholder="username"
            className="input input-bordered input-accent w-full max-w-xs m-3"
            name="username"
            autoComplete="off"
            {...register("username", {
              required: "The username is required",
              minLength: {
                value: 3,
                message: "Username must be at least 3 characters long",
              },
              maxLength: {
                value: 15,
                message: "Username must be at most 15 characters long",
              },
            })}
          />
          {errors?.username && errors.username.message}

          <input
            type="text"
            placeholder="email@email.com"
            className="input input-bordered input-accent w-full max-w-xs m-3"
            name="email"
            autoComplete="off"
            {...register("email", { required: "The email is required" })}
          />
          {errors?.email && errors.email.message}


          <input
            type="password"
            placeholder="your password"
            className="input input-bordered input-accent w-full max-w-xs m-3"
            name="password"
            {...register("password", { 
              required: "The password is required" 
            })}
          />
          {errors?.password && errors.password.message}

          <input
            type="password"

            placeholder="confirm password"
            className="input input-bordered input-accent w-full max-w-xs m-3"
            name="confirmPassword"
            {...register("confirmPassword", { 
              required: "Please confirm your password",
              validate: (value) =>
                value === password || "The passwords  do not match",

            })}
          />
          {errors?.confirmPassword && errors.confirmPassword.message}


          <input
            type="number"
            placeholder="your age"
            className="input input-bordered input-accent w-full max-w-xs m-3"
            name="age"
            {...register("age", {
              required: "The age is required",
              min: {
                value: 4,
                message: "Age must be at least 4 years old",
              },
              max: {
                value: 100,
                message: "Age must be at most 100 years old",
              },
            })}
          />
          {errors?.age && errors.age.message}

          <button className="btn btn-outline btn-accent m-3 btn-block">
            Register
          </button>
        </div>
      </form>

      {apiError && (
        <div className="alert alert-error shadow-lg">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="stroke-current flex-shrink-0 h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <span>{apiError}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Register;