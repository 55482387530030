import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const BlogList = () => {
  const [posts, setPosts] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 10;

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch(`${BASE_URL}blog/?page=${currentPage}&limit=${postsPerPage}&is_published=true`);
        const data = await response.json();
        setPosts(data);

        // Get total post count from API (replace with your actual logic)
        const totalCount = data.length || 100;
        setPageCount(Math.ceil(totalCount / postsPerPage));
      } catch (error) {
        console.error("Error fetching blog posts:", error);
      }
    };

    fetchPosts();
  }, [currentPage]);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
  };

  return (
    <div className="mx-auto p-4">
      <div className="mb-12">
        <div className="bg-gradient-to-r from-blue-200 to-purple-200 p-8 rounded-lg shadow-lg">
          <h1 className="text-6xl font-bold mb-4 text-left text-gray-800">
            Blog
          </h1>
          <p className="text-gray-700 text-left text-xl ">
            Tutorials in python designed to share insights, best practices, and updates about use-case and problem solving scenarios, including but not limited to artificial intelligence, machine learning, automation, and app development.
          </p>
        </div>
      </div>
      <hr className="border-t border-gray-300 my-8" /> 

      <div className="grid md:grid-cols-12 gap-8 custom-mr">
        {/* Blog Posts */}
        <div className="md:col-span-8 custom-ml">
          <ul>
            {posts.map((post) => (
              <li key={post.url_id} className="mb-8">
                <Link to={`/blog/${post.url_id}`} className="block">
                  <h3 className="text-xl font-semibold hover:underline">
                    {post.title}
                  </h3>
                </Link>
                <div className="flex items-center text-gray-600 text-sm mt-1">
                  <span className="mr-2">
                    By {post.author || "Unknown Author"}
                  </span>
                  <span>
                    {post.publication_date
                      ? new Date(post.publication_date).toLocaleDateString()
                      : "No Date"}
                  </span>
                </div>
                <p className="text-gray-700 mt-2 line-clamp-3">
                  {post.running_title}
                </p>
              </li>
            ))}
          </ul>

          {/* Pagination */}
          <div className="mt-8">
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}   

              onPageChange={handlePageClick}
              containerClassName={
                "pagination flex justify-center items-center"
              }
              pageClassName={"py-2 px-4 mx-1 rounded-lg"}
              pageLinkClassName={"text-gray-700 hover:text-blue-500"}
              activeClassName={"bg-blue-500 text-white"}
              previousClassName={"py-2 px-4 mx-1 rounded-lg"}
              previousLinkClassName={"text-gray-700 hover:text-blue-500"}
              nextClassName={"py-2 px-4 mx-1 rounded-lg"}
              nextLinkClassName={"text-gray-700 hover:text-blue-500"}
              breakClassName={"py-2 px-4 mx-1 rounded-lg"}
              breakLinkClassName={"text-gray-700"}
              disabledClassName={"opacity-50 cursor-not-allowed"}
            />
          </div>
        </div>

        {/* Advertisement */}
        <div className="md:col-span-4">
          <div className="bg-gray-100 p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-bold mb-4">Mastering python through completing trainings and quests</h3>
            <p className="text-gray-700 mb-6">
              Learn how to use python in real-world scenarios by completing trainings and quests.
            </p>
            <a
              href="/"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Learn More
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BlogList;