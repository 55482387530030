import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const BlogPost = () => {
  const { postId } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await fetch(`${BASE_URL}blog/${postId}`);
        const data = await response.json();
        setPost(data);
      } catch (error) {
        console.error("Error fetching blog post:", error);
      }
    };

    fetchPost();
  }, [postId]);

  if (!post) {
    return <div>Loading...</div>;
  }

  return (
    <div className="mx-auto p-4">
      <div className="mb-12">
        <div className="bg-gradient-to-r from-blue-200 to-purple-200 p-8 rounded-lg shadow-lg">
          <p className="text-middle text-xl ">
            Last updated: {post.publication_date
              ? new Date(post.publication_date).toLocaleDateString()
              : "No Date"}
          </p>
          <h1 className="text-4xl font-bold mb-4 text-middle text-gray-800">
            {post.title}
          </h1>
          <p className="text-gray-700 text-middle text-xl ">
            {post.author}
          </p>
        </div>
      </div>
      <hr className="border-t border-gray-300 my-8" />

      <div className="grid grid-cols-1 md:grid-cols-8 gap-8 custom-mr">
        <div className="md:col-span-6 tinymce-styles custom-ml" dangerouslySetInnerHTML={{ __html: post.content }} />

        {/* Advertisement */}
        <div className="md:col-span-2">
          <div className="bg-gray-100 p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-bold mb-4">Mastering python through completing trainings and quests</h3>
            <p className="text-gray-700 mb-6">
              Learn how to use python in real-world scenarios by completing trainings and quests.
            </p>
            <a
              href="/"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Learn More
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPost;