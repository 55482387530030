import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import useAuth from '../hooks/useAuth';

const Header = () => {
    const { auth, setAuth } = useAuth();
    let navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as needed
        };

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const logout = () => {
        setAuth({});
        navigate("/login", { replace: true });
    };

    const handleDropdownItemClick = (event) => {
        event.stopPropagation(); // Prevent event bubbling
        setDropdownOpen(false); // Close the dropdown
    };

    return (
        <div className="navbar bg-primary text-primary-content">
            <div className="flex-1">
                <Link className="btn btn-ghost normal-case text-xl" to="/">AstraCoders</Link>
                {/* Conditionally render logged-in status on larger screens */}
                {!isMobile && (
                    <span className="border-2 border-amber-500 px-2 py-1 rounded">
                        {auth?.username ? `The ${auth.tier} - ${auth?.username}` : "Not logged in"}
                    </span>
                )}
            </div>

            {/* Mobile-first navigation - Collapses into a dropdown on smaller screens */}
            <div className="flex-none">
                <ul className={`menu menu-horizontal p-0 ${isMobile ? 'hidden md:flex' : ''}`}>
                    {!auth?.username && (
                        <>
                            <li className="mx-1 text-lg"><Link to="/login">Login</Link></li>
                            <li className="mx-1 text-lg"><Link to="/register">Register</Link></li>
                        </>
                    )}
                    <li className="mx-1 text-lg"><Link to="/courses">Courses</Link></li>
                    <li className="mx-1 text-lg"><Link to="/blog">Blog</Link></li>
                    {auth?.username && (
                        <li className="mx-1 text-lg">
                            <button className="btn-warning" onClick={logout}>
                                Logout
                            </button>
                        </li>
                    )}
                </ul>

                {/* Hamburger menu for mobile */}
                {isMobile && (
                    <div className="dropdown dropdown-end">
                        <label
                            tabIndex={0}
                            className="btn btn-ghost btn-circle"
                            onClick={() => setDropdownOpen(!dropdownOpen)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                        </label>
                        {dropdownOpen && (
                        <ul tabIndex={0} className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-gray-800 text-white rounded-box w-52">
                                {/* Same menu items as above, but now in the dropdown */}
                                {!auth?.username && (
                                    <>
                                        <li><Link to="/login" onClick={handleDropdownItemClick}>Login</Link></li>
                                        <li><Link to="/register"  onClick={handleDropdownItemClick}>Register</Link></li>
                                    </>
                                )}
                                <li><Link to="/courses" onClick={handleDropdownItemClick}>Courses</Link></li>
                                <li><Link to="/blog" onClick={handleDropdownItemClick}>Blog</Link></li>
                                {auth?.username && (
                                    <>
                                        {/* <li>
                                            <span className="border-2 border-amber-500 p-1">
                                                Logged in as {auth?.username} - {auth.role}
                                            </span>
                                        </li> */}
                                        <li>
                                            <button className="btn-warning" onClick={logout}>
                                                Logout
                                            </button>
                                        </li>
                                    </>
                                )}
                        </ul>
                    )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Header;