import {Route, Routes} from "react-router-dom"


import Layout from "./components/Layout";
import HomePage from "./components/HomePage";
import Login from "./components/Login";
import Register from "./components/Register";
import NotFound from "./components/NotFound";
import RequireAuthentication from "./components/RequireAuthentication";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";

import EmailVerificationSent from "./components/EmailVerificationSent";
import VerifyEmail from "./components/VerifyEmail";

import Courses from './pages/Courses'
import Codeclass from './pages/Codeclass'
import BlogList from './pages/BlogList'
import BlogPost from './pages/BlogPost'

function App() {  

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<HomePage />} />
        <Route path="login" element={<Login/>} />
        <Route path="register" element={<Register/>} />
        <Route path="courses" element={<Courses/>} />
        <Route path="/email-verification-sent" element={<EmailVerificationSent />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/blog" element={<BlogList />} />
        <Route path="/blog/:postId" element={<BlogPost />} />

        <Route element={<RequireAuthentication />}>
          <Route path="courses/:id" element={<Codeclass />} />
          <Route path="*" element={<NotFound/>} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;