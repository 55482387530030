import React from "react";

const EmailVerificationSent = () => {
  return (
    <div className="mx-auto p-10 rounded-lg shadow-2xl">
      <h2 className="text-xl text-primary text-center font-bold my-2">
        Registration successful!
      </h2>
      <p className="text-center">
        A verification email has been sent to your inbox. Please click the
        link in the email to verify your account.
      </p>
    </div>
  );
};

export default EmailVerificationSent;