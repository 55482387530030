import Card from "../components/Card";
import Loading from "../components/Loading";
import { useState, useEffect } from 'react';
import courseService from '../services/CourseService'; // Import the service

const Courses = () => {
    const [courses, setCourses] = useState([]);
    const [category, setCategory] = useState('');
    const [isPending, setIsPending] = useState(true);
    const [page, setPage] = useState(1);

    const handleChangeCategory = (ev) => {
        setCourses([]);
        setCategory(ev.target.value);
        setIsPending(true);
    };

    const handleChangePage = (ev) => {
        setCourses([]);
        setPage(ev.target.value);
        setIsPending(true);
    };

    const fetchCourses = async () => {
        setIsPending(true);
        try {
            const fetchedCourses = await courseService.getList({
                category: category || undefined,
                page,
            });
            setCourses(fetchedCourses);
        } catch (error) {
            console.error('Error fetching courses:', error);
            // Handle error state if needed
        } finally {
            setIsPending(false);
        }
    };

    useEffect(() => {
        fetchCourses();
    }, [category, page]);

    return (
        <div className="content-container">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 bg-gray-900 text-white">
                <h2 className="font-bold font-mono text-lg text-center my-4">
                    Courses - {category ? category : "all categories"}
                </h2>
                <div className="flex flex-col sm:flex-row justify-between items-center mx-8 mb-4 bg-gray-700 p-4 rounded">
                    <div className="mb-4 sm:mb-0 w-full sm:w-auto">
                        <label htmlFor="courses" className="mr-2">Choose a category:</label>
                        <select name="courses" id="courses" onChange={handleChangeCategory} className="p-2 border rounded text-orange-600 w-full sm:w-auto">
                            <option value="">All courses</option>
                            <option value="Training (Novice)">Training (Novice)</option>
                            <option value="Quest (Novice)">Quest (Novice)</option>
                            <option value="Training (Apprentice)">Training (Apprentice)</option>
                            <option value="Quest (Apprentice)">Quest (Apprentice)</option>
                            <option value="Training (Adept)">Training (Adept)</option>
                            <option value="Quest (Adept)">Quest (Adept)</option>
                        </select>
                    </div>
                    <div className="w-full sm:w-auto">
                        <label htmlFor="page" className="mr-2">Choose a page:</label>
                        <select name="page" id="page" onChange={handleChangePage} className="p-2 border rounded text-orange-600 w-full sm:w-auto">
                            <option value="1">1</option>
                            <option value="2">2</option>
                        </select>
                    </div>
                </div>
                <div className="mx-8">
                    {isPending && <Loading category={category} />}
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                        {courses && courses.map((el) => (
                            <Card key={el._id} course={el} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Courses;